<template>
  <v-col class="pa-0 height-100 d-flex flex-column">
    <v-tabs class="tabs-link bb-gray flex-grow-0">
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.slug"
        :ripple="false"
        class="px-0 mx-4 fz-14 fw-600 hover-none"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <router-view class="flex-grow-1 scroll" style="height: calc(100% - 49px)" ref="page" :overlayShow.sync="overlayShow"/>
    <v-overlay
      :z-index="1"
      :value="overlayShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="closeAll"
    />
  </v-col>
</template>

<script>
export default {
  data () {
    return {
      overlayShow: false,
      tabs: [
        {
          name: 'Lead Table',
          slug: '/clients/leads'
        },
        {
          name: 'Company Table',
          slug: '/clients/companys'
        }
      ]
    }
  },
  methods: {
    closeAll() {
      this.$refs.page.closeEdit()
      this.$refs.page.closeInfo()
    }
  }
}
</script>

<style scoped>

</style>
